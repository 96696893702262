import httpUtil from "@/utils/httpUtil";

/**添加/修改员工考核 */
export const addEmployeeKpi =  params => httpUtil.post("/api/crmPc/employee/addEmployeeKpi", params);

/**添查询员工考核列表 */
export const employeeKpiList =  params => httpUtil.post("/api/crmPc/employee/employeeKpiList", params);

/**员工考核详情 */
export const getEmployeeKpiDetails =  params => httpUtil.post("/api/crmPc/employee/getEmployeeKpiDetails", params);

/**具体员工考核完成度 */
export const getEmployeeKpiUser =  params => httpUtil.post("/api/crmPc/employee/getEmployeeKpiUser", params);

/**撤销员工考核 */
export const deleteEmployeeKpi =  params => httpUtil.post("/api/crmPc/employee/deleteEmployeeKpi", params);

